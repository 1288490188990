<template>
  <ElDialog
    :visible="visible"
    :show-close="false"
    title="Comment"
  >
    <p>{{ comments }}</p>
    <div class="button-container d-flex justify-content-center">
      <ElButton
        type="primary"
        size="medium"
        @click="$emit('close')"
      >
        Close
      </ElButton>
    </div>
  </ElDialog>
</template>

<script>
export default {
  name: 'CommentsDialog',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    comments: {
      type: String,
      required: true,
    },
  },
};
</script>
