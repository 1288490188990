<template>
  <div>
    <SdSearchInput
      v-if="$viewport.lt.md"
      class="lease-search-input-container"
      placeholder="Search prospect"
      @input="prospectSearchQueryChanged"
    />
    <div
      v-if="$viewport.gt.md"
      class="ml-auto"
    >
      <div class="drop-down d-flex flex-column flex-md-row justify-content-end">
        <div class="pl-2 pr-0">
          <SdSearchInput
            v-if="$viewport.gt.md"
            class="lease-search-input-container"
            placeholder="Search prospect"
            @input="prospectSearchQueryChanged"
          />
        </div>
        <div class="pl-2 pr-0">
          <ElSelect
            v-model="currentFilters.addresses"
            class="multiple-select"
            multiple
            placeholder="Filter by address"
            clearable
            collapse-tags
            default-first-option
          >
            <ElOption
              v-for="(address, key) in filterOptions.addresses"
              :key="`address_${key}`"
              :label="address.label"
              :value="address.value"
            />
          </ElSelect>
        </div>
        <div class="pl-2 pr-0">
          <el-cascader
            v-model="currentFilters.locations"
            placeholder="Filter by state"
            clearable
            collapse-tags
            max-collapse-tags="2"
            :props="{ multiple: true }"
            :options="filterOptions.locations"
          >
            <template #default="{ node, data }">
              <span class="el-cascader-node__label">{{ data.label }}</span>
              <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
            </template>
          </el-cascader>
        </div>
        <div class="pl-2 pr-0">
          <ElSelect
            v-model="currentFilters.bedrooms"
            placeholder="Filter by beds"
            multiple
            clearable
            collapse-tags
            default-first-option
          >
            <ElOption
              v-for="(address, key) in filterOptions.bedrooms"
              :key="`bedrooms_${key}`"
              :label="address.label"
              :value="address.value"
            />
            <!--            <template #default="{ node, data }">-->
            <!--              <span class="el-cascader-node__label">{{ data.label }}</span>-->
            <!--            </template>-->
          </ElSelect>
        </div>
        <div class="pl-2 pr-0">
          <ElSelect
            v-model="currentFilters.members"
            placeholder="Filter by member"
            clearable
            collapse-tags
            multiple
          >
            <ElOption
              v-for="(members, index) in filterOptions.members"
              :key="index"
              :label="members.label"
              :value="members.value"
            />
          </ElSelect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import get from 'lodash.get';
import StateOptions from '@/constants/StateOptions';
import { reactive, watch } from '@vue/composition-api';
import cloneDeep from 'lodash.clonedeep';
import SdSearchInput from '@/components/common/form/SdSearchInput.vue';
import { getBedroomsOptions } from '@/pages/App/Lease/LeaseUtils';

export default {
  name: 'LeaseFilters',
  components: { SdSearchInput },
  props: {
    inquiries: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const filterOptions = reactive({
      addresses: [],
      locations: [],
      members: [],
      bedrooms: getBedroomsOptions(),
    });

    const currentFilters = reactive(cloneDeep(props.filters));

    watch(currentFilters, () => {
      context.emit('filter', currentFilters);
    }, { deep: true });

    watch(() => props.inquiries, (inquiries) => {
      getMembers().then((members) => {
        const inquiriesFilterOptions = getFilterOptionsFromInquiries(inquiries, members);
        filterOptions.addresses = inquiriesFilterOptions.addressOptions;
        filterOptions.locations = inquiriesFilterOptions.locationOptions;
        filterOptions.members = inquiriesFilterOptions.memberOptions;
      });
    }, { deep: true });

    function getMembers() {
      return context.root.$store.dispatch('Auth/getTeamMembers');
    }

    function getFilterOptionsFromInquiries(inquiries, members) {
      const addressOptions = [];
      const locationOptions = [];
      const memberOptions = [];

      inquiries.forEach((inquiry) => {
        const shortAddress = get(inquiry, 'unit.property.short_address');
        const vicinity = get(inquiry, 'unit.property.vicinity');
        const state = get(inquiry, 'unit.property.state');
        const member = members.find((member) => member.id === inquiry.listing_manager_id);

        if (!addressOptions.some((option) => option.value === shortAddress)) {
          addressOptions.push({
            value: shortAddress,
            label: shortAddress,
          });
        }

        if (!locationOptions.some((option) => option.value === state)) {
          const stateLabel = StateOptions.find((state) => state.value === state);
          locationOptions.push({
            value: state,
            label: stateLabel || state,
            children: [
              {
                value: vicinity,
                label: vicinity,
              },
            ],
          });
        } else {
          const locationOption = locationOptions.find((option) => option.value === state);
          if (!locationOption.children.some((option) => option.value === vicinity)) {
            locationOption.children.push({
              value: vicinity,
              label: vicinity,
            });
          }
        }

        if (member && !memberOptions.some((option) => option.value === member.id)) {
          memberOptions.push({
            value: member.id,
            label: member.full_name,
          });
        }
      });

      return {
        addressOptions,
        locationOptions,
        memberOptions,
      };
    }

    function prospectSearchQueryChanged(query) {
      currentFilters.prospectQuery = query;
    }

    return {
      filterOptions,
      currentFilters,
      prospectSearchQueryChanged,
    };
  },
};
</script>

<style scoped lang="scss">

</style>
