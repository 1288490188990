<template>
  <div
    class="board-card w-100 p-2"
    :class="borderClass"
    @mousedown="$emit('card-click', $event)"
  >
    <div
      class="board-card-header d-flex align-items-center font-15 text-gray-darker"
    >
      <ProspectProfileLink
        class="prospect-profile-link"
        :prospect="lead"
        opens-new-tab
      />
      <div class="d-flex align-items-center ml-auto">
        <BoardCardButtons
          class="board-card-buttons"
          :step="step"
          :property-id="inquiry.unit.property.id"
          :unit-id="inquiry.unit.id"
          :schedule-id="inquiry.schedule_id"
          :should-allow-inspection="!inquiry.inspection"
          :showing-profile="inquiry.unit.last_showing_profile"
          @schedule-tour="$emit('schedule-tour')"
          @cancel-tour="$emit('cancel-tour')"
          @reschedule-tour="$emit('reschedule-tour')"
        />
        <div
          v-if="comments"
          class="ml-2 pointer"
          @click="$emit('comments', comments)"
        >
          <ElTooltip :content="shorthandComments">
            <i class="sdicon-chat-bubble" />
          </ElTooltip>
        </div>
      </div>
    </div>
    <div class="board-card-content">
      <div class="w-100 font-13">
        <ElTooltip :content="unitFullAddress">
          <router-link
            class="d-inline-block board-card-content-value elipsis line-height-1.25"
            :to="{ name: 'app.units.show', params: { id: unit.property.id, unit_id: unit.id } }"
            target="_blank"
          >
            {{ unitFullAddress }}
          </router-link>
        </ElTooltip>
      </div>
      <div class="font-13 text-gray-dark elipsis">
        {{ activityDescription }}
      </div>
      <div class="d-flex justify-content-between align-items-center font-13">
        <span class="text-gray-dark">{{ source }}</span>
        <div class="bullets-between">
          <HotLeadBadge v-if="inquiry.is_hot_lead || inquiry.is_likely_to_apply" />
          <span
            v-if="inquiry.is_no_show"
            class="font-13 text-gray-dark"
          >No Show
          </span>
          <span
            v-if="inquiry.is_technical_issue"
            class="font-13 text-gray-dark"
          >Technical Issue
          </span>
          <template>
            <span
              v-if="inquiry.is_disqualified"
              class="font-13 text-gray-dark"
            >Disqualified
            </span>
            <span
              v-else-if="isPendingVerification"
              class="font-13 text-gray-dark"
            >Unverified
            </span>
          </template>
        </div>
      </div>
      <hr>
      <div class="d-flex w-100 justify-content-between font-13">
        <SdPhoneIndicator
          v-if="inquiry.lead.phone_number"
          class="flex-shrink-0 mr-3"
          with-icon
          :phone-number="inquiry.lead.phone_number"
          :formatted-phone-number="inquiry.lead.formatted_phone_number"
        />
        <SdEmailIndicator
          v-if="inquiry.lead.email"
          with-icon
          class="flex-shrink-1 elipsis w-auto"
          :email="inquiry.lead.email"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator.vue';
import SdEmailIndicator from '@/components/common/SdEmailIndicator.vue';
import HotLeadBadge from '@/components/prospect/badges/HotLeadBadge.vue';
import { getInquiryActivityDescription } from '@/utils/InquiryUtils';
import { getTourStatusDescription } from '@/utils/TourUtil';
import { computed } from '@vue/composition-api';
import momentUtil from '@/utils/MomentUtil';
import { isInquiryPendingVerification, isInquiryVerified } from '@/utils/IdentityVerificationUtil';
import BoardCardButtons from '@/pages/App/Lease/BoardCardButtons.vue';
import ProspectProfileLink from '../../../components/prospect/ProspectProfileLink.vue';

export default {
  name: 'BoardCard',
  components: {
    BoardCardButtons,
    HotLeadBadge,
    SdEmailIndicator,
    SdPhoneIndicator,
    ProspectProfileLink,
  },
  props: {
    inquiry: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
      validator: (value) => [1, 2, 3, 4, 5].includes(value),
    },
  },
  setup(props, context) {
    const { inquiry, step } = props;

    const {
      unit,
      comments,
      lead,
      tour_status,
      source,
    } = inquiry;

    const business = context.root.$store.getters['Auth/business'];
    const shouldNotAllowToDrag = step === 3 && (tour_status === 'no_show' || tour_status === 'technical_issue');
    const unitFullAddress = `${unit.property.short_address} ${unit.name ? `- ${unit.name}` : ''}`;
    const shorthandComments = comments && comments.length > 100 ? `${comments.slice(0, 97)}...` : comments;
    const localTzTourDate = momentUtil(inquiry.tour_at, unit.property?.timezone);
    const isPendingVerification = isInquiryPendingVerification(inquiry, lead, unit.last_showing_profile, unit.last_lease_period);

    const activityDescription = inquiry.tour_at
      ? getTourStatusDescription(
        inquiry.tour_status,
        localTzTourDate,
        inquiry.is_self_showing,
        inquiry.is_open_house,
      )
      : getInquiryActivityDescription(inquiry.inquiry_created_at, unit.property?.timezone);

    const borderClass = computed(() => {
      const isProblem = inquiry.is_no_show || inquiry.is_technical_issue;
      const isOpportunity = inquiry.is_likely_to_apply || inquiry.is_hot_lead;

      if (isOpportunity) {
        return 'is-opportunity';
      }
      if (isProblem) {
        return 'is-problem';
      }

      return null;
    });

    return {
      unit,
      comments,
      lead,
      shouldNotAllowToDrag,
      source,
      activityDescription,
      unitFullAddress,
      shorthandComments,
      borderClass,
      isPendingVerification,
    };
  },
};
</script>

<style scoped lang="scss">
.board-card {
  background: $white;
  border-radius: 5px;
  border-left: 4px solid $white;
  border-right: 4px solid transparent;

  &.is-opportunity {
    border-left-color: $orange;
  }

  &.is-problem {
    border-left-color: map-get($gray-colors, "dark");
  }
}

.prospect-profile-link {
  position: relative;
  color: theme-color(primary);

  &:before {
    content: "";
    position: absolute;
    display: inline-flex;
    width: 100%;
    height: 0px;
    bottom: 0;
    background-color: transparent;
    transition: all 0.125s ease-in-out;
  }

  &:hover {
    color: theme-color(primary-dark);

    &:before {
      background-color: theme-color(primary-dark);
      height: 2px;
    }
  }
}

.bullets-between {
  display: flex;
  align-items: center;

  & > * {
    display: inline-flex;
    align-items: center;
  }

  & > *:not(:first-child)::before {
    display: inline-block;
    content: '•';
    color: gray-color(dark);
    font-size: 1.5rem;
    line-height: 0;
    margin: 0 5px;
  }
}
</style>
