<template>
  <div class="lease-empty-state d-flex flex-column justify-content-center align-items-center mt-5">
    <img
      :src="mainImage"
      alt="Lease Empty State"
      width="371"
      height="279"
    >
    <div class="lease-empty-state-text-container text-center">
      <h3 class="font-21">
        Welcome to Showdigs!
      </h3>
      <p
        v-if="isNoProperties"
        class="lease-empty-state-text text-gray-dark font-17"
      >
        None of your properties are currently on the market with Showdigs.
      </p>
      <p
        v-if="!isNoProperties"
        class="lease-empty-state-text text-gray-dark font-17"
      >
        Ensure your inquiries are set up to <a
          href="https://help.showdigs.com/kb/en"
          target="_blank"
        >forward </a> to Showdigs
      </p>
    </div>
    <SdActionButtons
      v-if="isNoProperties"
      :actions="actions"
    />
  </div>
</template>

<script>
import SdActionButtons from '@/components/common/SdActionButtons';
import { ref } from '@vue/composition-api';
import NoPropertiesImage from '@/assets/welcome-to-showdigs.svg';
import NoInquiriesImage from '@/assets/empty-state-no-inquiries.svg';

export default {
  name: 'LeaseEmptyState',
  components: 'SdActionButtons',
  props: {
    isNoProperties: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const actions = ref({});
    const { isNoProperties } = props;
    const mainImage = isNoProperties ? NoPropertiesImage : NoInquiriesImage;
    actions.value = {
      addInquiry: {
        label: 'Add property',
        icon: 'sdicon-plus',
        routeData: { name: 'app.properties.add' },
      },
    };

    return {
      actions,
      mainImage,
    };
  },
};
</script>
<style>
.lease-empty-state-text-container {
  margin-top: 1.5rem;
}
</style>
