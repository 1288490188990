<template>
  <div class="d-flex">
    <el-tooltip
      v-if="isShowingTypeNotPublishListing && step === 1"
      content="Schedule Tour"
      placement="top"
      class="ml-1"
    >
      <i
        role="button"
        class="d-block sdicon-calendar-plus-o text-dark"
        @click="$emit('schedule-tour')"
      />
    </el-tooltip>

    <el-tooltip
      v-if="step !== 1"
      :content="step === 2 ? 'View Tour' : 'Tour Report'"
      placement="top"
      class="ml-1"
    >
      <router-link
        :to="{ name: 'app.tours.show', params: { id: scheduleId } }"
        target="_blank"
        class="text-dark"
      >
        <i
          class="d-block"
          :class="step === 2 ? 'sdicon-calendar-empty' : 'sdicon-calendar-check-o'"
        />
      </router-link>
    </el-tooltip>

    <el-tooltip
      v-if="step === 5 && shouldAllowInspection"
      content="Order a report"
      placement="top"
      class="ml-1"
    >
      <router-link
        :to="{ name: 'app.condition-report.move_in_out', params: { id: propertyId, unit_id: unitId } }"
        target="_blank"
        class="text-dark d-block"
      >
        <i
          role="button"
          class="d-block sdicon-doc-text"
          @click="$router.push({ name: 'app.condition-report.move_in_out', params: { id: propertyId, unit_id: unitId } })"
        />
      </router-link>
    </el-tooltip>
  </div>
</template>

<script>

import Showing from '@/constants/Showing';

export default {
  props: {
    step: {
      type: Number,
      required: true,
      validator: (value) => [1, 2, 3, 4, 5].includes(value),
    },
    propertyId: {
      type: Number,
      required: true,
    },
    scheduleId: {
      type: Number,
      required: false,
      default: null,
    },
    unitId: {
      type: Number,
      required: true,
    },
    showingProfile: {
      type: Object,
      required: true,
    },
    shouldAllowInspection: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isShowingTypeNotPublishListing = props.showingProfile.type !== Showing.TYPE_PUBLISH_LISTING;
    return {
      isShowingTypeNotPublishListing,
    };
  },
};
</script>
