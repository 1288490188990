<template>
  <SdPage id="lease-page">
    <SdPageHeader
      v-if="$viewport.lt.md"
      title="Your Leasing Funnel"
      class="sd-lease-header"
    />
    <div class="d-flex leasing-header mb-3">
      <SdActionButtons
        :actions="actions"
        :is-force-desktop-view="true"
      />
      <LeaseFilters
        class="ml-auto"
        :inquiries="allInquiries"
        :filters="currentFilters"
        @filter="updateFilters"
      />
    </div>

    <div
      v-if="uiFlags.isLoadingInquiries"
      class="row font-15 justify-content-center"
    >
      <div class="col-1 mt-4">
        <ElSpinner color="primary" />
      </div>
    </div>

    <div
      v-if="!uiFlags.isLoadingInquiries"
      class="sd-lease-board-inner-container d-flex justify-content-center"
    >
      <div :class="{'sd-lease-board': true, 'four-cols-board': !isAppFolioIntegrated}">
        <div
          v-for="column in leaseBoardColumns"
          :key="column.step"
          class="sd-lease-column py-3"
        >
          <div class="sd-lease-column-top d-flex px-3 mb-3 justify-content-between">
            <div class="sd-lease-column-top-title-container d-flex pb-1">
              <div class="sd-lease-column-top-left d-flex align-items-center">
                <h3 class="mb-0">
                  {{ column.title }}
                </h3>
                <div class="sd-card-count mx-2 font-13 font-weight-bold">
                  {{ !Number.isNaN(getTotalSum(column.name)) ? getTotalSum(column.name) : 0 }}
                </div>
                <div class="sd-card-percentage px-1 font-13">
                  {{ getPercentage(column.name) }}
                </div>
              </div>
            </div>
          </div>
          <draggable
            v-if="filteredInquiriesByColumn"
            class="draggable-list px-2"
            :class="`draggable-list-${column.step} ${getActiveClass(isDisabled[column.step])} ${!uiFlags.isEmptyStateVisible
              ? 'draggable-list-has-height'
              : ''}`"
            :v-model="filteredInquiriesByColumn[column.name]"
            :list="filteredInquiriesByColumn[column.name]"
            :data-column-id="`${column.step}`"
            :disabled="isDisabled[column.step] || uiFlags.isWaitingForResponse || uiFlags.isForceDisableDrag"
            group="my-group"
            @start="getDisabled(column.step, $event)"
            @end="refreshActiveColumns($event)"
            @change="handleCardMove($event, column.step)"
          >
            <BoardCard
              v-for="inquiry in filteredInquiriesByColumn[column.name]"
              :key="`${inquiry.id} + ${inquiry.tour_at} + ${inquiry.signed_at} + ${inquiry.comments} + ${inquiry.source}`"
              ref="boardCard"
              :inquiry="inquiry"
              :step="column.step"
              class="mb-2"
              @comments="openCommentDialog"
              @card-click="handleCardClick"
              @schedule-tour="scheduleTourForLead(inquiry)"
              @cancel-tour="cancelTour(inquiry)"
              @reschedule-tour="rescheduleTour(inquiry)"
            />
            <div
              v-if="!filteredInquiriesByColumn[column.name].length"
              class="text-center text-gray-dark font-14"
            >
              {{ uiFlags.isEmptyStateVisible ? "" : "No inquiries currently match this stage" }}
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <UnitAddInquiryDialog
      v-if="uiFlags.isAddInquiryDialogVisible"
      :unit="unitRef"
      :lead="currentLead"
      @close="addInquiryDialogClosed"
    />
    <TourRescheduleDialog
      v-if="uiFlags.isRescheduleDialogVisible"
      :schedule="scheduleRef"
      :visible="uiFlags.isRescheduleDialogVisible"
      @close="rescheduleDialogClosed"
    />
    <TourCancelDialog
      v-if="uiFlags.isCancelDialogVisible"
      :schedule="scheduleRef"
      :visible="uiFlags.isCancelDialogVisible"
      @close="cancelDialogClosed"
    />
    <CommentsDialog
      :visible="uiFlags.isCommentDialogVisible"
      :comments="currentComments"
      @close="closeCommentsDialog"
    />
    <UnitStopDialog
      v-if="uiFlags.isUnitStopDialogVisible"
      :visible="uiFlags.isUnitStopDialogVisible"
      :unit="unitRef"
      :prospect="currentLead"
      @close="closeUnitStopDialog"
    />
    <PropertyRentedDialog
      v-if="uiFlags.isShowPropertyRentedDialog"
      :title="`${unitRef.property.short_address}${unitRef.name ? ` - ${unitRef.name}` : ''}`"
      :first-time-opened="true"
      :is-rented="true"
      :showing-profile-id="unitRef.last_showing_profile.id"
      :visible.sync="uiFlags.isShowPropertyRentedDialog"
      @close="closePropertyShowRentedDialog"
    />
    <LeaseEmptyState
      v-if="uiFlags.isEmptyStateVisible"
      :is-no-properties="uiFlags.isNoProperties"
    />
  </SdPage>
</template>

<script>
import {
 computed, reactive, ref, watch,
} from '@vue/composition-api';
import get from 'lodash.get';
import { canBeCancelled, canBeRescheduled } from '@/utils/ScheduleUtil';
import { showErrorNotify } from '@/utils/NotifyUtil';
import draggable from 'vuedraggable';
import UnitAddInquiryDialog from '@/components/unit/UnitAddInquiryDialog';
import TourRescheduleDialog from '@/components/tour/TourRescheduleDialog';
import TourCancelDialog from '@/components/tour/TourCancelDialog';
import UnitStopDialog from '@/components/unit/UnitStopDialog';
import PropertyRentedDialog from '@/components/property/PropertyRentedDialog';
import StateOptions from '@/constants/StateOptions';
import CommentsDialog from '@/components/common/CommentsDialog';
import { BusinessSourceTypes } from '@/constants/BusinessSource';
import SdActionButtons from '@/components/common/SdActionButtons.vue';
import SdPageHeader from '@/components/common/layout/SdPageHeader.vue';
import SdPage from '@/components/common/layout/SdPage.vue';
import {
  inquiryAddressFilterFn,
  inquiryBedsFilterFn,
  inquiryLeadNameFilterFn,
  inquiryLocationFilterFn,
  inquiryMemberFilterFn,
} from '@/utils/InquiryUtils';
import LeaseFilters from '@/pages/App/Lease/LeaseFilters.vue';
import cloneDeep from 'lodash.clonedeep';
import { leaseBoardColumns, defaultDisabledLeaseColumns, EMPTY_LEASE_COLUMNS } from '@/pages/App/Lease/LeaseUtils';
import BoardCard from './BoardCard.vue';

import LeaseEmptyState from './LeaseEmptyState';

const REFRESH_INQUIRIES_INTERVAL_TIME = 60000;

export default {
  name: 'Lease',
  components: {
    LeaseFilters,
    SdPage,
    SdPageHeader,
    SdActionButtons,
    draggable,
    BoardCard,
    UnitAddInquiryDialog,
    TourRescheduleDialog,
    TourCancelDialog,
    UnitStopDialog,
    PropertyRentedDialog,
    CommentsDialog,
    LeaseEmptyState,
  },
  beforeRouteLeave(to, from, next) {
    this.stopInquiriesInterval();
    next();
  },
  setup(props, context) {
    const { $store } = context.root;
    const user = $store.getters['Auth/user'];

    const isAppFolioIntegrated = !!user.business?.source?.client_id
        && user.business?.source?.source_type === BusinessSourceTypes.APPFOLIO;

    if (!isAppFolioIntegrated && EMPTY_LEASE_COLUMNS.applied) {
      removeAppliedColumn();
    }

    const currentFilters = reactive({
      prospectQuery: '',
      locations: [],
      members: [],
      addresses: [],
      bedrooms: [],
    });

    const isDisabled = ref(defaultDisabledLeaseColumns);
    const pendingInquiry = ref(null);

    const allInquiriesByColumn = ref(EMPTY_LEASE_COLUMNS);
    const allInquiries = computed(() => Object.values(allInquiriesByColumn.value).flat());

    const filteredInquiriesByColumn = ref(null);
    const displayedInquiriesCount = computed(() => allInquiries.value.length);

    const uiFlags = reactive({
      isLoadingInquiries: true,
      isAddInquiryDialogVisible: false,
      isRescheduleDialogVisible: false,
      isCancelDialogVisible: false,
      isUnitStopDialogVisible: false,
      isShowPropertyRentedDialog: false,
      isCommentDialogVisible: false,
      isEmptyStateVisible: false,
      isNoProperties: true,
      isWaitingForResponse: false,
      isForceDisableDrag: false,
    });

    const actions = ref({
      addInquiry: {
        label: 'Add inquiry',
        icon: 'sdicon-user-plus',
        disabled: uiFlags.isNoProperties,
        buttonClass: 'action-button',
        size: 'small',
        buttonType: 'primary',
        cb: () => (uiFlags.isAddInquiryDialogVisible = true),
      },
    });

    const currentLead = ref(null);
    const unitRef = ref(null);
    const scheduleRef = ref({});
    const currentComments = ref('');
    const isDragging = ref(false);

    let cardMovement = {
      fromCol: null,
      toCol: null,
    };

    getInquiries();

    let inquiriesInterval;
    startInquiriesInterval();

    function startInquiriesInterval() {
      inquiriesInterval = setInterval(async () => await getInquiries(), REFRESH_INQUIRIES_INTERVAL_TIME);
    }

    function stopInquiriesInterval() {
      if (inquiriesInterval) {
        clearInterval(inquiriesInterval);
      }
    }

    function handleCardClick(event) {
      const tagName = event.target.tagName;
      uiFlags.isForceDisableDrag = tagName === 'SPAN';
    }

    async function getInquiries() {
      try {
        const {
          columns: {
            recent, scheduled, completed, applied, signed,
          },
          inquiries_count,
          properties_count,
        } = await $store.dispatch('Lease/get') || {};

        allInquiriesByColumn.value = {
          recent,
              scheduled,
              completed,
        ...(isAppFolioIntegrated ? { applied } : {}),
              signed,
        };

        uiFlags.isEmptyStateVisible = inquiries_count === 0;
        uiFlags.isNoProperties = properties_count === 0;
        actions.value.addInquiry.disabled = uiFlags.isNoProperties;

        if (!filteredInquiriesByColumn.value) {
          filteredInquiriesByColumn.value = cloneDeep(allInquiriesByColumn.value);
        } else {
          filterInquiries();
        }
      } catch (e) {
        showErrorNotify(context, e);
      } finally {
        uiFlags.isLoadingInquiries = false;
      }
    }

    async function scheduleTourForLead({ lead, unit }) {
      stopInquiriesInterval();
      currentLead.value = lead;
      unitRef.value = unit;
      uiFlags.isAddInquiryDialogVisible = true;
    }

    function openCommentDialog(comments) {
      currentComments.value = comments;
      uiFlags.isCommentDialogVisible = true;
    }

    function closeCommentsDialog() {
      uiFlags.isCommentDialogVisible = false;
    }

    function handleCardMove(event, step) {
      if (event.added) {
        cardMovement.toCol = step;
        pendingInquiry.value = {
          indexTo: event.added.newIndex,
          columnToName: getColumnName(cardMovement.toCol),
        };
      } else if (event.removed) {
        cardMovement.fromCol = step;
      }
      if (cardMovement.fromCol && cardMovement.toCol) {
        pendingInquiry.value = {
          ...pendingInquiry.value,
          inquiry: event.removed.element,
          indexFrom: event.removed.oldIndex,
          columnFromName: getColumnName(cardMovement.fromCol),
        };
        handleCardDrop(cardMovement.fromCol, cardMovement.toCol, event.removed.element);
      }
    }

    function handleCardDrop(fromCol, toCol, inquiry) {
      if (fromCol === 1 && toCol === 2) {
        scheduleTourForLead(inquiry);
      } else if (fromCol === 3 && toCol === 4) {
        applyTour(inquiry);
      } else if (fromCol === 4 && toCol === 5) {
        signApplication(inquiry);
      } else if (fromCol === 4 && toCol === 3) {
        applyTour(inquiry, false);
      }
      cardMovement = {
        fromCol: null,
        toCol: null,
      };
    }

    async function rescheduleTour({ schedule_id }) {
      scheduleRef.value = await $store.dispatch('Business/getTour', schedule_id);
      stopInquiriesInterval();
      uiFlags.isRescheduleDialogVisible = true;
    }

    async function applyTour(inquiry, isApplied = true) {
      try {
        uiFlags.isWaitingForResponse = true;
        await $store.dispatch('Lease/editInquiry', { id: inquiry.id, applied: isApplied });
      } catch (e) {
        showErrorNotify(context, e);
        undoDrag();
      }
      uiFlags.isWaitingForResponse = false;
    }

    function signApplication({ lead, unit }) {
      stopInquiriesInterval();
      currentLead.value = lead;
      unitRef.value = unit;
      uiFlags.isUnitStopDialogVisible = true;
    }

    async function closeUnitStopDialog(status) {
      uiFlags.isUnitStopDialogVisible = false;
      currentLead.value = null;

      if (status === 'success') {
        try {
          uiFlags.isShowPropertyRentedDialog = true;
          await getInquiries();
        } catch (e) {
          showErrorNotify(context, e);
        }
      } else {
        undoDrag();
      }
    }

    function closePropertyShowRentedDialog() {
      unitRef.value = null;
      uiFlags.isShowPropertyRentedDialog = false;
      startInquiriesInterval();
    }

    function rescheduleDialogClosed(status) {
      scheduleRef.value = null;
      startInquiriesInterval();
      uiFlags.isRescheduleDialogVisible = false;
      if (!status || status !== 'success') {
        undoDrag();
      }
    }

    function getColumnName(step) {
      return leaseBoardColumns.find((column) => column.step === step).name;
    }

    async function addInquiryDialogClosed(status) {
      startInquiriesInterval();
      uiFlags.isAddInquiryDialogVisible = false;
      currentLead.value = null;
      unitRef.value = null;

      if (status === 'success' || status === undefined) {
        try {
          uiFlags.isWaitingForResponse = true;
          await getInquiries();
        } catch (e) {
          showErrorNotify(context, e);
        }
      } else {
        undoDrag();
      }
      uiFlags.isWaitingForResponse = false;
    }

    function getActiveClass(isDisabled) {
      if ((isDisabled && isDragging.value) || uiFlags.isWaitingForResponse) {
        return 'not-active';
      }
      if (isDisabled && !isDragging.value) {
        return 'undraggable';
      }
      return '';
    }

    async function cancelTour({ schedule_id }) {
      scheduleRef.value = await $store.dispatch('Business/getTour', schedule_id);
      stopInquiriesInterval();
      uiFlags.isCancelDialogVisible = true;
    }

    function cancelDialogClosed(status) {
      if (status === 'success') {
        moveCardFromScheduleToRecentByTourId(scheduleRef.value.tour_id);
      }
      startInquiriesInterval();
      uiFlags.isCancelDialogVisible = false;
    }

    function getTotalSum(columnName) {
      return filteredInquiriesByColumn.value[columnName].length;
    }

    function moveCardFromScheduleToRecentByTourId(tour_id) {
      let card;
      allInquiriesByColumn.value.scheduled.forEach((inquiry, index) => {
        if (inquiry.tour_id === tour_id) {
          card = { ...inquiry };
          allInquiriesByColumn.value.scheduled.splice(index, 1);
        }
      });
      allInquiriesByColumn.value.recent.unshift(card);
      scheduleRef.value = null;
    }

    function undoDrag() {
      if (get(pendingInquiry, 'value.columnFromName') && get(pendingInquiry, 'value.columnToName')) {
        allInquiriesByColumn.value[pendingInquiry.value.columnFromName].splice(pendingInquiry.value.indexFrom, 0, pendingInquiry.value.inquiry);
        allInquiriesByColumn.value[pendingInquiry.value.columnToName].splice(pendingInquiry.value.indexTo, 1);
        cardMovement = {
          fromCol: null,
          toCol: null,
        };
        pendingInquiry.value = {};
      }
    }

    watch(currentFilters, filterInquiries, { deep: true });

    function updateFilters(filters) {
      currentFilters.prospectQuery = filters.prospectQuery;
      currentFilters.locations = filters.locations;
      currentFilters.addresses = filters.addresses;
      currentFilters.bedrooms = filters.bedrooms;
      currentFilters.members = filters.members;
    }

    function filterInquiries() {
      const inquiries = EMPTY_LEASE_COLUMNS;

      for (const key in allInquiriesByColumn.value) {
        inquiries[key] = allInquiriesByColumn.value[key]
            .filter(inquiryLeadNameFilterFn(currentFilters.prospectQuery))
            .filter(inquiryLocationFilterFn(currentFilters.locations))
            .filter(inquiryAddressFilterFn(currentFilters.addresses))
            .filter(inquiryBedsFilterFn(currentFilters.bedrooms))
            .filter(inquiryMemberFilterFn(currentFilters.members));
      }

      filteredInquiriesByColumn.value = inquiries;
    }

    function getPercentage(columnName) {
      const columnInquiriesCount = filteredInquiriesByColumn.value[columnName].length;
      const percentage = Math.round((columnInquiriesCount / displayedInquiriesCount.value) * 100);

      return !Number.isNaN(percentage) ? `${percentage}%` : `${0}%`;
    }

    function getDisabled(step, event) {
      const tourStatus = event.item.__vue__._props.inquiry.tour_status;

      isDragging.value = true;
      switch (step) {
        case 3:
          isDisabled.value = {
            ...defaultDisabledLeaseColumns,
            1: true,
            4: tourStatus !== 'completed',
          };
          break;
        case 4:
          isDisabled.value = {
            ...defaultDisabledLeaseColumns,
            1: true,
            3: false,
            5: false,
          };
          break;
      }
    }

    function refreshActiveColumns() {
      isDragging.value = false;
      isDisabled.value = defaultDisabledLeaseColumns;
    }

    function removeAppliedColumn() {
      const appliedColumnsIndex = 3;
      leaseBoardColumns.splice(appliedColumnsIndex, 1);
      delete EMPTY_LEASE_COLUMNS.applied;
      delete defaultDisabledLeaseColumns[4];
    }

    return {
      StateOptions,
      leaseBoardColumns,
      allInquiries,
      actions,
      currentComments,
      currentFilters,
      currentLead,
      inquiriesInterval,
      filteredInquiriesByColumn,
      isAppFolioIntegrated,
      isDisabled,
      scheduleRef,
      uiFlags,
      unitRef,
      addInquiryDialogClosed,
      canBeCancelled,
      canBeRescheduled,
      cancelDialogClosed,
      cancelTour,
      closeCommentsDialog,
      closePropertyShowRentedDialog,
      closeUnitStopDialog,
      get,
      getActiveClass,
      getDisabled,
      getPercentage,
      getTotalSum,
      updateFilters,
      handleCardClick,
      handleCardMove,
      openCommentDialog,
      refreshActiveColumns,
      rescheduleDialogClosed,
      rescheduleTour,
      scheduleTourForLead,
      stopInquiriesInterval,
    };
  },
};
</script>

<style lang="scss">
@import '@/styles/utils';

#lease-page {
  .leasing-header {
    max-width: 1791px;
    margin: 0 auto;
  }

  .action-button {
    position: relative;
    height: 40px;

    @include media-breakpoint-down(md) {
      margin-right: 1rem;
      height: 30px;
      top: 1px;
    }
  }
}

.sd-lease-header {
  text-align: center;

  @include media-breakpoint-down(md) {
    text-align: left;
  }
}

.sd-lease-board {
  display: grid;
  grid-template-columns: repeat(5, 347px);
  overflow-x: scroll;
  overflow-y: hidden;
  column-gap: 24px;
}

.four-cols-board {
  grid-template-columns: repeat(4, 347px);
}

.sd-lease-column {
  border-radius: 7px;
  background: #dfe5eb;
}

.sd-lease-column-top-title-container {
  width: 100%;
  border-bottom: 2px $white solid;
}

.draggable-list-has-height {
  height: 70vh;
}

.draggable-list {
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: gray-color();
  }
}

.sd-lease-button {
  width: 111px;
  height: 31px;
}

.sd-card-count {
  $card-count-size: 18px;
  width: $card-count-size;
  height: $card-count-size;
  background: $white;
  border-radius: 50%;
  text-align: center;
  line-height: $card-count-size;
}

.sd-card-percentage {
  display: inline-block;
  background: #c8e0ea;
  color: theme-color("blue");
}

.el-cascader {
  width: 100%;
  height: 40px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 40px;
  background: $white;
  border-radius: 4px;

  @include media-breakpoint-down(md) {
    height: 32px;
    line-height: 32px;
  }

  .el-input__icon {
    position: relative;
    z-index: 2;
  }

  .el-input__inner {
    cursor: pointer;
    position: relative;
    top: -1px;
    -webkit-appearance: none;
    background-color: $white;
    background-image: none;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px !important;
    font-size: 14px;
    line-height: 14px;
    outline: none;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    border: 1px solid gray-color("light");

    @include media-breakpoint-down(md) {
      height: 32px;
    }

    &:hover {
      border: 1px solid gray-color("");
    }

    &:focus {
      border-color: theme-color(primary);
    }
  }

  .el-cascader__tags {
    position: absolute;
    height: 100%;
    top: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex: 0;
    max-width: calc(100% - 20px);

    .el-tag {
      margin-left: 6px;
      margin-top: 2px;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      background-color: rgba(56, 146, 255, 0.1490196078);
      color: theme-color("primary");
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .el-tag:nth-child(2) {
      width: 40px;
      margin-right: 10px;
    }
  }
}

.lease-search-input-container {
  @include search-input-with-focus($with-mobile-small-icon-expander: false,
  $desktop-input-height: 40px,
  $mobile-input-height: 32px);
}

.el-cascader__dropdown {
  .el-cascader-menu {
    width: 230px;
  }

  .el-scrollbar {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .el-input__icon {
    position: relative;
    z-index: 2;
  }

  .el-cascader-menu__list {
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 6px 0;
    list-style: none;
    box-sizing: border-box;
    background: $white;
  }

  .el-cascader-panel {
    display: flex;
    background: $white;
  }

  .el-cascader-menu__list {
    min-width: 230px;
    box-sizing: border-box;
    color: #606266;
    border-right: 1px solid #e4e7ed;
  }

  .el-cascader-node {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 30px 0 20px;
    height: 40px;
    line-height: 1rem;
    outline: none;

    @include media-breakpoint-down(md) {
      height: 32px;
    }
  }

  .el-cascader-node__postfix {
    position: absolute;
    right: 10px;
  }

  .el-checkbox {
    margin: 0 5px 0 0;
  }
}

.undraggable .board-card {
  cursor: not-allowed;
}

.not-active {
  opacity: 0.5;
}

.drop-down {
  @include dropdown-select($select-width: auto);

  .el-date-editor {
    background: $white;
  }
}
</style>
