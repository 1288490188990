export function getBedroomsOptions() {
  return [
      { value: 'Studio', label: 'Studio' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4+' },
    ];
}

export const leaseBoardColumns = [
  {
    step: 1,
    title: 'Recently Inquired',
    name: 'recent',
  },
  {
    step: 2,
    title: 'Tour Scheduled',
    name: 'scheduled',
  },
  {
    step: 3,
    title: 'Tour Completed',
    name: 'completed',
  },
  {
    step: 4,
    title: 'Application',
    name: 'applied',
  },
  {
    step: 5,
    title: 'Lease Signed',
    name: 'signed',
  },
];

export const EMPTY_LEASE_COLUMNS = {
  recent: [],
  scheduled: [],
  completed: [],
  applied: [],
  signed: [],
};

export const defaultDisabledLeaseColumns = {
  1: true,
  2: true,
  3: false,
  4: false,
  5: true,
};
